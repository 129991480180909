
.confetti-animation {
    font-size: 7rem; /* Tamaño del emoji */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: popInOut 2s ease forwards;
    pointer-events: none;
    z-index: 9999;
  }
  
  @keyframes popInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    30% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.2);
    }
    70% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  @media (max-width: 480px) {
    .confetti-animation {
      font-size: 3rem; /* Tamaño del emoji */
    }
}
  