.reset-container {
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.reset-form {
    width: 100%;
    max-width: 320px;
    text-align: left;
}

.reset-form input[type="password"]{
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 16px;
}

.reset-button {
    width: 100%;
    padding: 12px;
    background-color: #191E32;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}

.reset-button:hover {
    background-color: #155bc5;
}