.register-container {
    display: grid; /* Usar grid */
    grid-template-columns: 1fr 1fr; /* Dos columnas iguales */
    gap: 20px; /* Espacio entre columnas */
    padding: 20px; /* Espaciado interno */
    background-color: #ffffff; /* Fondo del contenedor */
    max-width: 100%;
}

.left-section {
    padding: 10px; /* Espaciado interno */
    background-color: #ffffff; /* Fondo para distinguir */
}

.right-section {
    padding: 10px; /* Espaciado interno */
    background-color: #ffffff; /* Fondo para distinguir */
}

.logo-mathethon {
    color: #191E32;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.tagline {
    font-size: 20px;
    color: #606770;
}

.register-form {
    width: 100%;
    max-width: 320px;
    text-align: center;
}

.register-form input[type="email"],
.register-form input[type="text"],
.register-form input[type="password"] {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 16px;
}

.register-button {
    width: 100%;
    padding: 12px;
    background-color: #191E32;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}

.register-button:hover {
    background-color: #155bc5;
}

.forgot-password {
    display: block;
    margin: 15px 0;
    color: #1877f2;
    text-decoration: none;
    font-size: 14px;
}

.forgot-password:hover {
    text-decoration: underline;
}

hr {
    border: none;
    border-top: 1px solid #dddfe2;
    margin: 20px 0;
}


.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .popup-content button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }

  
@media (max-width: 480px) {
    .register-container {
        grid-template-columns: 1fr;
        gap: 0px;
    }
    .left-section {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .right-section {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .right-section h1 {
        font-size: 20px;
    }
}