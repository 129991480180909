/* Formulario de Contacto */
.container-contact {
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.contact-form-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    font-size: 14px;
    font-weight: 600;
    color: #555555;
    margin-bottom: 5px;
}

.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 16px;
}

.contact-form textarea {
    resize: vertical;
    height: 100px;
}

.contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #191E32;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}

.contact-form button:hover {
    background-color: #155bc5;
}
