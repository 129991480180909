
/* Contenedor principal */
.statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin: 0 auto; /* Centra el contenedor en el eje horizontal */
    max-width: 600px;
}

/* Título */
.statistics-container h2 {
    color: #444;
    margin-bottom: 20px;
}

/* Tabla de ranking */
.ranking-table {
    width: 100%;
    max-width: 600px;
    border-collapse: collapse;
    margin: 0 auto; /* Esto centra la tabla horizontalmente */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Encabezados */
.ranking-table thead th {
    background-color: #2c3e50;
    color: #fff;
    padding: 12px 15px;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center; /* Centra el contenido horizontalmente */
    vertical-align: middle; /* Centra el contenido verticalmente */
    padding: 10px; /* Espaciado dentro de las celdas */
}

/* Filas alternas */
.ranking-table tbody tr:nth-child(even) {
    background-color: #f7f7f7;
}

/* Estilo general para filas */
.ranking-table tbody tr {
    transition: background-color 0.3s ease;
}

.ranking-table tbody tr:hover {
    background-color: #ececec;
}

/* Celdas */
.ranking-table td {
    padding: 12px 15px;
    color: #333;
    text-align: center; /* Centra el contenido horizontalmente */
    vertical-align: middle; /* Centra el contenido verticalmente */
    padding: 10px; /* Espaciado dentro de las celdas */
}

/* Estilos para el top 3 */
.rank-1 td {
    background-color: #ffdf91; /* Oro */
    font-weight: bold;
}

.rank-2 td {
    background-color: #c0c0c0; /* Plata */
    font-weight: bold;
}

.rank-3 td {
    background-color: #cd7f32; /* Bronce */
    font-weight: bold;
}

.medal-1::before {
    content: "🥇"; /* Medalla de oro */
}

.medal-2::before {
    content: "🥈"; /* Medalla de oro */
}

.medal-3::before {
    content: "🥉"; /* Medalla de oro */
}

.not-medal-1 {
    display: none;
}

.not-medal-2 {
    display: none;
}

.not-medal-3 {
    display: none;
}


/* Estilo responsive */
@media (max-width: 600px) {
    .ranking-table thead {
        display: none; /* Oculta encabezados en pantallas pequeñas */
    }

    .ranking-table, .ranking-table tbody, .ranking-table tr, .ranking-table td {
        display: block;
        width: 100%;
    }

    .ranking-table tbody tr {
        margin-bottom: 15px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .ranking-table td {
        padding: 10px;
        text-align: right;
        position: relative;
    }

    .ranking-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #666;
    }

    .ranking-table td.points::before {
        content: "Puntos: ";
      }

      .ranking-table td.position::before {
        content: "Posición: ";
      }
}
